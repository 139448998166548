.custom-addItemModal-send-btn {
    height: 24px !important;
    text-transform: capitalize !important;
    padding: 0.4px 8px !important;
    font-size: 12px !important;
    background-color: #5755FF !important;
    color: #FFFFFF !important;
    box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.1), 0px 0px 0px 1px #5E5ADB;
    border-radius: 4px;
    min-width: 54px !important;
}

.custom-addItemModal-delete-btn {
    height: 24px !important;
    text-transform: capitalize !important;
    padding: 0.4px 8px !important;
    font-size: 12px !important;
    background: #FFFFFF !important;
    color: #464F60 !important;
    box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.1), 0px 0px 0px 1px rgba(70, 79, 96, 0.16);
    border-radius: 4px
}

.custom-addItemModal-text-field {
    height: 40px !important;
    background: #FFFFFF;
    border-radius: 6px 6px 6px 6px;
    font-size: 12px !important;
}

.custom-addItemModal-text-field .MuiOutlinedInput-input,
.custom-addItemModal-text-field .MuiInputLabel-formControl {
    font-size: 12px !important;
    /* line-height: 23px !important; */
}

.custom-addItem-modal {
    border-radius: 12px;
    padding: 0px 20px 16px 20px;
}

.custom-addUnit-head {
    font-style: normal;
    font-weight: 700;
    font-size: 23px;
    line-height: 48px;
    letter-spacing: -0.022em;
    color: #0E0D35;
    margin: 0rem;
}

.custom-add-item-alert {
    margin-top: 1rem !important;
}