.custom-modal {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 70%;
    height: auto;
    background-color: white;
    border: '2px solid #000';
    border-radius: 10px;
}

.custom-table-header {
    padding: 0rem 1rem !important;
    font-size: 18px;
}

.custom-table-column {
    padding: 0rem 1rem !important;
    font-size: 14px;
}

.custom-table-btn-selected {
    background-color: #5755FF !important;
    color: #FFFFFF !important;
    margin: 0px 8px 5px 2px !important;
    text-transform: none !important;
}

.custom-table-btn {
    background-color: #FFFFFF !important;
    color: #464F60 !important;
    margin: 0px 8px 5px 0px !important;
    text-transform: none !important;
    padding: 2px 10px !important;
}

.custom-table-paper {
    background-color: #E9EDF5;
    border: 5px solid #E9EDF5;
    border-radius: 4px !important;
    margin-right: 26px;
}

.custom-btn-icon {
    color: #BCC2CE;
    margin-right: 2px;
}

.custom-date-paper {
    background-color: #E9EDF5;
    border: 5px solid #E9EDF5;
    border-radius: 4px !important;
}

.custom-date-contain {
    background-color: #E9EDF5;
    border: 2px solid #E9EDF5;
    border-radius: 4px !important;
    margin: 0rem 1rem !important;
}

.custom-table-date-picker .MuiInputBase-root {
    height: 35px !important;
    font-size: 13px !important;
}

.custom-table-date-picker .css-nxo287-MuiInputBase-input-MuiOutlinedInput-input {
    padding: 5.5px 14px !important;
}

.custom-btn-reset {
    background-color: #FFFFFF !important;
    color: #464F60 !important;
    margin-right: 1rem !important;
    text-transform: none !important;
    height: 1.8rem;
    box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.1), 0px 0px 0px 1px rgba(70, 79, 96, 0.16);
    border-radius: 6px;
}

.custom-btn-apply {
    background-color: #5755FF !important;
    color: #FFFFFF !important;
    text-transform: none !important;
    height: 1.8rem;
    box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.1), 0px 0px 0px 1px #5E5ADB;
    border-radius: 6px;
}

.custom-send-btn {
    color: #464F60 !important;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    align-items: center;
    text-align: center;
}

.custom-send-btn:focus {
    color: #5755FF !important;
    box-shadow: 0px 0px 0px 0.5px rgba(0, 0, 0, 0.08), 0px 2px 6px rgba(0, 0, 0, 0.25);
    border-radius: 4px;
    background-color: #FFFFFF;
}

.custom-tablesettings-checkbox .MuiCheckbox-root {
    color: #5755FF !important;
}