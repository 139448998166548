.custom-ReSubmissionLineItems-bill {
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 20px;
    letter-spacing: 0.02em;
    margin: 0;
    color: #464F60;
}


.custom-ReSubmissionLineItems-save-btn {
    height: 24px !important;
    padding: 0.4px 8px !important;
    font-size: 12px !important;
    background-color: #5755FF !important;
    color: #FFFFFF !important;
    box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.1), 0px 0px 0px 1px #5E5ADB;
    border-radius: 4px;
    text-transform: initial !important;
}

.custom-ReSubmissionLineItems-cancel-btn {
    height: 24px !important;
    padding: 0.4px 8px !important;
    font-size: 12px !important;
    background: #FFFFFF !important;
    color: #464F60 !important;
    text-transform: initial !important;
    box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.1), 0px 0px 0px 1px rgba(70, 79, 96, 0.16);
    border-radius: 4px;
    font-weight: 500;
    line-height: 20px;
    letter-spacing: 0.02em;
}

.custom-ReSubmissionLineItems-checkbox .MuiSvgIcon-root {
    border-radius: 4px !important;
    height: 0.8em !important;
}

.custom-ReSubmissionLineItems-checkbox .MuiFormControlLabel-label {
    font-size: 12px;
}

.custom-ReSubmissionLineItems-paper {
    padding: 0rem 1rem 0rem 1rem;
    box-shadow: none !important;
}

.custom-ReSubmissionLineItems-container {
    padding: 0rem 0rem 0rem 0rem;
}

.custom-ReSubmissionLineItems-header {
    width: 100%;
    background-color: #E4ECF7 !important;
    text-transform: initial !important;
    font-style: normal;
    font-weight: 700 !important;
    font-size: 14px;
    line-height: 35px;
    text-align: center;
    color: #171C26 !important;
    backdrop-filter: blur(4px);
}

.custom-lineitems-textField {
    font-size: 12px !important;
}

.custom-lineitems-textField .MuiInputBase-inputSizeSmall {
    font-size: 12px !important;
}

.custom-lineitems-selectfield .MuiSelect-select {
    padding: 5.5px 14px !important;
    font-size: 12px;
}