
.custom-stepper-head-label{
    font-style: normal;
    font-weight: 700 !important;
    font-size: 14px;
    line-height: 17px;
    letter-spacing: 0.01em;
    color: #232323;
    padding-top: 1px !important;
}
.custom-History-Invoice{
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    margin: 0rem;
    letter-spacing: 0.02em;
    color: #00B929;
}
.custom-History-Purchase{
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    margin: 0rem;
    letter-spacing: 0.02em;
    color: #171C26;
    /* padding-right: 32px; */
}
.custom-History-stepper{
    padding: 2rem 0rem 3rem 3rem;
}
.custom-History-purchase{
    padding: 2rem 0rem 0rem 0rem;
}
.MuiStepConnector-vertical .MuiStepConnector-line::before{
    height: 29px !important;
    width: 3px !important;
    border-radius: 50%;
    background-color: #00B929 !important;
}

.MuiStepConnector-vertical {
    -webkit-flex: 1 1 auto;
    -ms-flex: 1 1 auto;
    flex: 1 1 auto;
    margin-left: 13px;
}
.custom-orderHistory-labelhead{
    font-style: normal;
    font-weight: 700;
    font-size: 12px;
    line-height: 17px;
    letter-spacing: 0.01em;
    color: #232323;
}
.custom-History-stepper .MuiStepLabel-labelContainer{
    padding-left: 20px;
}

.custom-stepper-label {
    padding: 0px 2px !important;
} 
.custom-History-stepper .MuiStepConnector-line {
    display: inline-block !important;
    min-height: 0px !important;
    min-width: 0px !important;
}
.custom-History-stepper .MuiStepConnector-vertical {
    line-height: 0 !important;
}
.custom-innerstepper-icon{
    height: 6px !important;
    width: 6px !important;
}
.custom-stepper-label .MuiStepLabel-label{
    font-size: 12px !important;
}
.custom-stepper-label .MuiStepLabel-labelContainer{
    padding-left: 28px !important;
}
.custom-stepper-label-background{
    height: 10px !important;
    width: 10px !important;
}