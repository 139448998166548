.custom-app-bar {
    background-color: #FFFFFF !important;
    color: black !important;
    box-shadow: 0px 1px 4px -1px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px rgba(0, 0, 0, 0.12) !important
}

.sidebar-item .MuiListItemText-primary {
    color: #626C7A !important;
    font-size: 12px !important;
}

.sidebar-item-selected .MuiListItemText-primary {
    color: #5755FF !important;
    font-size: 12px !important;
}

.custom-list-btn {
    margin: 0px 8px 0px 8px !important;
    border-radius: 5px !important;
}

.custom-list-btn-selected {
    margin: 0px 8px 0px 8px !important;
    border-radius: 5px !important;
    background-color: #F4F3FF !important;
}

.custom-list-btn:hover {
    background-color: #F4F3FF !important;
}

.custom-tool-bar {
    min-height: 50px !important;
}

.custom-text {
    background-color: #F4F3FF !important;
    border-radius: 50px !important;
}

.custom-appbar-padding {
    padding: 9px 5px !important;
}

.notification-icon {
    vertical-align: middle !important;

    font-size: 25px !important;
    color: #A1ACB3;
}

.notification-dot .MuiBadge-badge {
    margin: 3.5px !important;
    border: 1.5px solid #FFF !important;
}

.Profile-icon {
    vertical-align: middle !important;
    font-size: 25px !important;
    color: #A1ACB3;
}

.custom-notification-padding {
    padding: 2px 0px !important;
    text-align: center !important;
}

.custom-profile-padding {
    padding: 0px 0px !important;
    text-align: center !important;
}

.custom-list .MuiListItem-root {
    padding-left: 3px !important;
    padding-right: 3px !important;
}

.custom-list .MuiListItem-padding {
    padding-top: 4px;
}

.css-1r9jet7 {
    min-height: 51px !important;
}

.MuiListItemIcon-root {
    margin: 0px -6px !important;
}

.custom-box {
    background-color: #F8F8F9;
}

.custom-sidebar-profile-menu .MuiPopover-paper {
    width: 15rem;
    top: 52px !important;
    /* left:1670px !important */
}

.custom-profile-padding .custom-sidebar-profile-menu-icon {
    padding: 3px !important;
}

.custom-profile-padding .MuiPaper-elevation {
    top: 53px !important;
}

.custom-list .MuiList-root {
    padding-top: 0px !important;
}

.custom-generatekey-head {
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 18px;
    align-items: center;
    letter-spacing: 0.03em;
    text-transform: initial;
    color: #171C26;
}

.custom-sidebar-img {
    width: 62%;
}
.hover-menu-item:hover p {
    color: rgb(87, 85, 255);
}

.hover-menu-item:hover svg{
    color: rgb(87, 85, 255) !important;
}
.custom-sidebar-username {
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 8px;
    align-items: center;
    letter-spacing: 0.03em;
    text-transform: capitalize;
    color: #171C26;
}

@media screen and (min-device-width: 1440px) and (max-device-width: 1920px) {
    .custom-sidebar-profile-menu .MuiPopover-paper {
        top: 52px !important;
        /* left:1190px !important */
    }
}
@media screen and (min-device-width: 1280px) and (max-device-width: 1439px) {
    .custom-sidebar-profile-menu .MuiPopover-paper {
        /* top: 52px !important; */
        /* left:1030px !important */
    }
}
@media screen and (min-device-width: 1024px) and (max-device-width: 1279px) {
   
}
@media screen and (min-device-width: 900px) and (max-device-width: 1023px) {
   
}
@media screen and (min-device-width: 600px) and (max-device-width: 899px) {
  
}