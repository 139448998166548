.custom-itemList-btn {
    height: 25px !important;
    padding: 0.4px 8px !important;
    font-size: 12px !important;
    background-color: #5755FF !important;
    color: #FFFFFF !important;
    box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.1), 0px 0px 0px 1px #5E5ADB;
    border-radius: 6px;
    border-radius: 4px;
    min-width: 50px !important;
    text-transform: initial !important;
}

.custom-itemList-field .MuiInputBase-root {
    height: 30px !important;
    background: #FFFFFF;
    border-radius: 6px 6px 6px 6px;
    font-size: 12px !important;
}

.custom-itemList-field .MuiInputBase-input {
    padding: 5px 0px !important;
}

.custom-itemlist-fieldItem-2 {
    padding-left: 0px !important;
    padding-top: 0px !important;
}

.custom-itemlist-fieldItem-1 .MuiGrid-item {
    padding-left: 16px !important;
    padding-top: 0px !important;
}

.custom-itemList-header {
    font-size: 23px;
}

.custom-itemList-icon {
    font-size: 18px !important;
    box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.1), 0px 0px 0px 1px rgba(70, 79, 96, 0.16);
    border-radius: 4px;
    margin: 10px 0px 0px 0px;
    height: 26px;
    width: 26px;
    text-align: center;
    padding-top: 3px;
}
.custom-item-table-icon:hover {
    color: rgb(87, 85, 255) !important;
 }
 
.custom-itemList-field .MuiInputBase-formControl {
    padding-left: 5px;
}


@media only screen and (max-device-width: 320px) {
    .custom-item-table-icon {
        height: 15px !important;
        width: 15px !important;
    }

    .custom-itemList-icon {
        font-size: 11px;
        margin: 9px0px 0px 0px;
        height: 26px;
        width: 26px;
        padding-top: 6px;
        border-radius: 4px;
    }
}

@media screen and (min-device-width: 320px) and (max-device-width: 539px) {
    .custom-item-table-icon {
        height: 15px !important;
        width: 15px !important;
    }

    .custom-itemList-icon {
        font-size: 11px;
        margin: 9px0px 0px 0px;
        height: 26px;
        width: 26px;
        padding-top: 6px;
        border-radius: 4px;
    }
}

@media screen and (min-device-width: 540px) and (max-device-width: 767px) {
    .custom-item-table-icon {
        height: 15px !important;
        width: 15px !important;
    }

    .custom-itemList-icon {
        font-size: 11px;
        margin: 9px0px 0px 0px;
        height: 26px;
        width: 26px;
        padding-top: 6px;
        border-radius: 4px;
    }
}

@media screen and (min-device-width: 768px) and (max-device-width: 992px) {
    .custom-item-table-icon {
        height: 15px !important;
        width: 15px !important;
    }

    .custom-itemList-icon {
        font-size: 11px;
        margin: 9px0px 0px 0px;
        height: 26px;
        width: 26px;
        padding-top: 6px;
        border-radius: 4px;
    }
}

@media screen and (min-device-width: 1366px) and (max-device-width: 1440px) {
    .custom-itemList-btn {
        height: 27px !important;
        padding: 0.4px 8px !important;
    }
}

@media screen and (min-device-width: 1440px) and (max-device-width: 1599px) {
    .custom-itemList-btn {
        height: 25px !important;
        padding: 0.4px 8px !important;
    }

}

@media screen and (min-device-width: 1600px) and (max-device-width: 1919px) {
    .custom-itemList-btn {
        height: 25px !important;
        padding: 0.4px 8px !important;
    }
}

@media screen and (min-device-width: 1920px) and (max-device-width: 2521px) {
    .custom-itemList-btn {
        height: 25px !important;
        padding: 0.4px 8px !important;
    }
}

@media screen and (min-device-width: 2560px) and (max-device-width: 2921px) {
    .custom-itemList-btn {
        height: 25px !important;
        padding: 0.4px 8px !important;
    }
}