.custom-lineItems-table-head {
    font-style: normal;
    font-weight: 500 !important;
    font-size: 12px !important;
    line-height: 13px !important;
    overflow-wrap: break-word;
    align-items: center;
    letter-spacing: 0.03em;
    padding: 15px 0px 15px 0px !important;
    text-transform: initial !important;
    color: #687182 !important;
}

.custom-lineItems-table-fields {
    font-style: normal;
    font-weight: 400 !important;
    font-size: 12px !important;
    line-height: 20px;
    align-items: center;
    padding: 8px !important;
    letter-spacing: 0.02em;
    color: #171C26 !important;
    padding: 15px 3px 15px 3px !important;
}

.custom-lineItems-table-container .MuiTableRow-head {
    background-color: #F4F7FC;
}

.custom-ReSubmissionLineItems-textField .MuiOutlinedInput-input {
    background: #FFFFFF;
    border-radius: 6px;
    font-size: 12px;
}

.custom-lineitems-checkbox .MuiSvgIcon-root {
    /* color: rgba(0, 0, 0, 0.1) !important; */
    border-radius: 4px !important;
    height: 0.8em !important;
}

.custom-lineItems-table-container .MuiTableCell-body {
    /* padding: 8px 7px 8px 7px !important; */
}

.custom-ReSubmissionLineItems-textField .MuiOutlinedInput-input {
    background: #FFFFFF;
    border-radius: 6px;
    font-size: 12px;
}

.custom-ReSubmissionLineItems-contentText {
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    align-items: center;
    color: #464F60;
    margin: 7px 0px !important;
}

.custom-lineItems-table-container {
    box-shadow: none !important;
}

.custom-ReSubmissionLineItems-Remove-btn {
    height: 24px !important;
    padding: 0.4px 8px !important;
    font-size: 12px !important;
    background-color: #E01313 !important;
    color: #FFFFFF !important;
    box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.1), 0px 0px 0px 1px #E01313;
    border-radius: 4px;
    text-transform: initial !important;
}

.custom-ReSubmissionLineItems-Reset-btn {
    height: 24px !important;
    padding: 0.4px 8px !important;
    font-size: 12px !important;
    background-color: #5755FF !important;
    color: #FFFFFF !important;
    box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.1), 0px 0px 0px 1px #5E5ADB;
    border-radius: 4px;
    text-transform: initial !important;
}