.custom-status-paper {
    padding: 2px 5px 2px 5px;
    box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0) !important;
}

.custom-link-order {
    color: #5E5ADB;
    text-decoration: none !important;
}

.custom-customer-table .css-1ex1afd-MuiTableCell-root {
    padding: 5px !important;
    font-size: 12px;
}

.custom-customer-table .css-1ygcj2i-MuiTableCell-root {
    padding: 7px 0px !important;
    font-size: 12px !important;
    background-color: #E5E7EA;
    color: #687182 !important;
    font-weight: 500 !important;
}

.custom-customer-table .tss-ylo811-MUIDataTableSelectCell-headerCell {
    background-color: #E5E7EA;
}

.custom-customer-table .css-1w1rijm-MuiButtonBase-root-MuiButton-root {
    min-width: 16px !important;
    padding: 2px 7px !important;
    font-size: 12px !important;
    font-weight: 500 !important;
}

.custom-customer-table .css-12wnr2w-MuiButtonBase-root-MuiCheckbox-root {
    padding: 4px !important;
}

.custom-customer-table .MuiSvgIcon-root {
    font-size: 16px !important;
}

.custom-customer-table .css-2ulfj5-MuiTypography-root {
    font-size: 16px !important;
}

.custom-customer-table .css-11mde6h-MuiPaper-root {
    border-radius: 0px !important;
    box-shadow: none !important;
    margin: 0.7rem 1rem !important;
}

.custom-customer-table .css-hyum1k-MuiToolbar-root {
    display: none !important;
}

.custom-status-icon {
    font-size: 8px;
    margin: 0px 4px 0px 0px;
}

.custom-customer-table .css-nfmglb-MuiTableCell-root {
    padding: 0px !important;
}