.custom-caracal-img {
    width: 75%;
}

.sidebar-font {
    font-size: 12px !important;
}

.custom-login-paper {
    background: #FFFFFF;
    box-shadow: 0px 109px 337px rgba(0, 0, 0, 0.0815322), 0px 45.5376px 140.791px rgba(0, 0, 0, 0.117129);
    border-radius: 15px;
}

.custom-login-backgroundImage {
    background-image: url('../../../public/login/Group.png');
    background-repeat: no-repeat;
    background-size: cover;
    height: 95.7vh;
    background-position: center;
}

.custom-loginpage-container {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.custom-img-grid {
    display: flex;
    align-items: center;
    justify-content: center;
}


@media only screen and (max-device-width: 320px) {
    .custom-login-paper {
        width: 90% !important;
    }

    .custom-caracal-img {
        width: 96%;
    }

    .custom-login-backgroundImage {
        height: 110vh;
    }

    .custom-loginpage-container {
        padding: 1.12rem 0rem;
    }
}

@media screen and (min-device-width: 320px) and (max-device-width: 539px) {
    .custom-login-paper {
        width: 90% !important;
    }

    .custom-caracal-img {
        width: 96%;
    }

    .custom-login-backgroundImage {
        height: 110vh;
    }

}

@media screen and (min-device-width: 540px) and (max-device-width: 767px) {
    .custom-login-paper {
        width: 90% !important;
    }

    .custom-caracal-img {
        width: 96%;
    }

    .custom-login-backgroundImage {
        height: 120vh;
    }
}

@media screen and (min-device-width: 768px) and (max-device-width: 992px) {
    .custom-login-paper {
        width: 80% !important;
    }

    .custom-caracal-img {
        width: 97%;
    }
}

@media screen and (min-device-width: 993px) and (max-device-width: 1024px) {
    .custom-login-backgroundImage {
        height: 575px;
    }

    .custom-caracal-img {
        width: 74%;
    }
}

@media screen and (min-device-width: 1025px) and (max-device-width: 1279px) {
    .custom-login-backgroundImage {
        height: 97vh;
    }
}

@media screen and (min-device-width: 1281px) and (max-device-width: 1365px) {
    .custom-loginpage-container {
        padding: 9.12rem 0rem;
    }
}

@media screen and (min-device-width: 1366px) and (max-device-width: 1440px) {
    .custom-login-backgroundImage {
        height: 97vh;
    }
}

@media screen and (min-device-width: 1440px) and (max-device-width: 1599px) {
    .custom-login-backgroundImage {
        height: 97vh;
    }
}

@media screen and (min-device-width: 1600px) and (max-device-width: 1919px) {
    .custom-login-backgroundImage {
        height: 97.5vh;
    }

}

@media screen and (min-device-width: 1920px) and (max-device-width: 2521px) {
    .custom-login-backgroundImage {
        height: 97.7vh;
    }
}

@media screen and (min-device-width: 2560px) and (max-device-width: 2921px) {
    .custom-login-backgroundImage {
        height: 98.3vh;
    }
}