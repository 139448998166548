.custom-invoice-header {
    width: 100%;
    background-color: #E4ECF7 !important;
    font-style: normal;
    font-weight: 700 !important;
    text-transform: initial !important;
    font-size: 14px;
    text-align: center;
    line-height: 35px;
    color: #171C26 !important;
    backdrop-filter: blur(4px);
}

.custom-invoice-selectField {
    background: #FFFFFF;
    border: 1px solid #E5E7EA;
    border-radius: 6px;
    text-align: start;
}

.custom-invoice-selectField-formcontrol .MuiInputLabel-formControl {
    font-size: 12px;
    color: #464F60 !important;
}

.custom-invoice-selectField-formcontrol .MuiOutlinedInput-input {
    padding: 5.5px 14px;
    font-size: 12px;
    color: #464F60 !important;
}

.custom-invoice-datepicker-item .MuiInputBase-input {
    height: 3px;
    /* padding: 7px 14px 7px 16px !important; */
    font-size: 12px;
    color: #464F60 !important;
}

.custom-invoice-textField .MuiInputLabel-formControl {
    background: #FFFFFF;
    border-radius: 6px;
    font-style: normal;
    font-weight: 500 !important;
    font-size: 12px !important;
    align-items: center;
    letter-spacing: 0.02em;
    color: #464F60 !important;
}

.custom-invoice-textField .MuiInputBase-input {
    padding: 5.5px 14px;
    font-size: 12px;
}

.custom-invoiceHeader-paper {
    padding: 0rem 1rem 0rem 1rem;
    box-shadow: none !important;
}

.custom_invoiceHeader_text {
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 20px;
    margin: 0;
    align-items: center;
    letter-spacing: 0.02em;
    color: #464F60;
}

.custom_invoiceHeader_bill {
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 20px;
    align-items: center;
    letter-spacing: 0.02em;
    margin: 0;
    color: #464F60;
    text-align: start;
}

.custom_invoiceHeader_bill_head {
    font-style: normal;
    font-weight: 600;
    margin: 0;
    font-size: 12px;
    line-height: 20px;
    align-items: center;
    letter-spacing: 0.02em;
    color: #464F60;
    text-align: start;
}

.custom-invoice-viewAddress {
    margin: 0;
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 20px;
    letter-spacing: 0.25px;
    color: #5755FF;
    cursor: pointer;
}

.custom-invoice-datepicker-item .MuiInputLabel-formControl {
    font-size: 12px !important;
    line-height: 14px;
}