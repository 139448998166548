.custom-logs-heading {
    font-style: normal;
    overflow-wrap: break-word;
    font-weight: 600 !important;
    font-size: 16px !important;
    line-height: 24px !important;
    color: #47494d;
}

.custom-logs-subject {
    font-style: normal;
    font-weight: 500 !important;
    font-size: 16px !important;
    line-height: 24px !important;
    color: #626C7A;
}
.custom-logs-heading-title{
    font-style: normal;
    font-weight: 700;
    font-size: 25px;
    line-height: 32px;
    color: #0E0D35;
}
.custom-logs-data-grid .MuiDataGrid-columnHeadersInner{
    background: #f0f6f9 !important ;
}
.custom-logs-data-grid .MuiDataGrid-virtualScrollerContent {
    font-size: 12px !important;
  }
  
  .custom-logs-data-grid .MuiDataGrid-columnHeaderTitle {
    font-size: 15px !important;
    font-weight: 600 !important;
    /* color:#7a7a7a !important; */
  }
  
  .custom-logs-data-grid {
    border-width: 0px !important;
  }
  .custom-logs-data-grid .MuiDataGrid-columnHeaderTitleContainer{
    justify-content: start !important;
  }
  .custom-logs-data-grid .MuiDataGrid-cell{
    justify-content: start !important;
    padding: 0px 4px !important;
  }
  .custom-logs-data-grid  .MuiDataGrid-selectedRowCount {
    display: none !important;
  }
  .custom-logs-data-grid .MuiDataGrid-footerContainer{
    justify-content: end;
  }
  .custom-logs-data-grid .MuiDataGrid-overlayWrapper{
    height: 50px !important;
  }