.custom-tax-table-head{
    font-style: normal;
    font-weight: 500 !important;
    font-size: 12px !important;
    line-height: 6px !important;
    align-items: center;
    letter-spacing: 0.03em;
    text-transform: initial !important;
    color: #687182 !important;
}
.custom-tax-table-fields{
    font-style: normal;
    font-weight: 400 !important;
    font-size: 12px !important;
    line-height: 20px;
    align-items: center;
    padding: 8px !important;
    letter-spacing: 0.02em;
    color: #171C26 !important;
}
.custom-TaxSummaryTable-table-container .MuiTableRow-head{
    background-color: #F4F7FC;
}