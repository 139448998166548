.custom-register-button {
    background: #FFA400 !important;
    border-radius: 100px !important;
    font-style: normal !important;
    font-weight: 600 !important;
    font-size: 14px !important;
    line-height: 25px !important;
    color: #FFFFFF !important;
    text-transform: initial !important;
}

.custom-register-fields .MuiInputBase-input {
    font-size: 12px !important;
    padding: 11.5px 14px;
}

.custom-Registerpassword-textfield .MuiSvgIcon-root {
    height: 18px !important;
    width: 18px !important;
}

.custom-register-heading {
    font-style: normal;
    font-weight: 700 !important;
    font-size: 28px !important;
    line-height: 18px !important;
    color: #171C26 !important;
    text-align: start;
}

.custom-Registerpassword-textfield .MuiInputBase-input {
    padding: 9.5px 14px;
}

.custom-register-grid {
    padding-top: 0px !important;
}

@media screen and (min-device-width: 2560px) and (max-device-width: 2921px) {
    .custom-register-heading {
        padding: 2rem 0rem 0rem 7rem;
    }
}