
.custom-comment-row{
    padding: 0rem 0rem 0rem 0rem;
}
.custom-basic-comments{
    padding: 0rem 1rem !important;
    font-size: 14px;
    font-weight: 700 !important;
}

.custom-comment-grid{
    padding: 2px 11px;
}
.custom-comment-send-btn {
    color: white !important;
    background-color: #5755FF !important;
    text-transform: initial !important;
    padding: 0px 0px !important;
    min-width: 58px !important;
}
.custom-paper-OrderAcksComment{
    margin: 1rem 0rem 1rem 0rem;
}
.custom-error-message{
    color: #d32f2f;
    font-size: 12px;
    margin: 0;
}
.custom-orderComment-expansion{
    margin-top: 0rem !important;
    margin-bottom: 0rem !important;
    padding: 0px 16px !important;
}

.custom-orderComment-expansion .MuiTimelineItem-missingOppositeContent:before {
    content: none !important;
}

.custom-orderComment-expansion .MuiTimelineDot-root {
    background-color:#00B929 !important;
}
.custom-orderComment-expansion .MuiTimelineContent-root {
    padding: 2px 6px !important;
}
.custom-orderComment-comments{
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 20px;
    display: flex;
    align-items: center;
    letter-spacing: 0.02em;
    color: #171C26
}
.custom-orderComment-time{
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 20px;
    display: flex;
    align-items: center;
    letter-spacing: 0.02em;
    color: #171C26
}
