.custom-order-modal {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 40%;
    height: 30%;
    background-color: white;
    border: '2px solid #000';
    border-radius: 10px !important;
    box-shadow: none !important;
}

.model-paper {
    padding: 30px;
    box-shadow: none !important;
}

.model-tilte {
    text-align: center;
    font-size: 1.5rem !important;
}

.custom-file-upload .MuiInputBase-input {
    font-size: 12px !important;
    padding: 11.5px 0px !important;
}

.custom-file-upload .MuiInputBase-formControl {
    padding-left: 0px !important;
}

.custom-customer-field {
    height: 40px !important;
}

.custom-customer-field .MuiSelect-select {
    min-height: 0px !important;
    font-size: 12px !important;
}

.custom-customer-field .MuiSelect-select {
    padding: 5.5px 14px !important;
    line-height: 12px !important;
}
/* Media query */
@media screen and (min-device-width: 540px) and (max-device-width: 768px) {
    .custom-order-modal {
        height: 16%;
    }
}
@media screen and (min-device-width: 768px) and (max-device-width: 992px) {
    .custom-order-modal {
        height: 21%;
    }
}
@media screen and (min-device-width: 1440px) and (max-device-width: 1599px) {
    .custom-order-modal {
        height: 25%;
    }
}
@media screen and (min-device-width: 1600px) and (max-device-width: 1919px) {
    .custom-order-modal {
        height: 27%;
    }
}