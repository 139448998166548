.custom-shipment-table-head {
    font-style: normal;
    font-weight: 500 !important;
    font-size: 12px !important;
    line-height: 6px !important;
    align-items: center;
    letter-spacing: 0.03em;
    text-transform: initial !important;
    color: #687182 !important;
}

.custom-shipment-table-fields {
    font-style: normal;
    font-weight: 400 !important;
    font-size: 12px !important;
    line-height: 20px !important;
    letter-spacing: 0.02em;
    color: #171C26 !important;
    padding: 14px 20px 14px 20px !important;
    text-transform: initial !important;
    width: 18rem !important;
    text-align: center !important;
}

.custom-shipping-summary {
    width: 100%;
    background-color: #E4ECF7 !important;
    color: #171C26 !important;
    font-style: normal;
    font-weight: 700 !important;
    font-size: 12px;
    line-height: 6px !important;
    align-items: center;
    backdrop-filter: blur(4px);
    text-transform: initial !important;
    text-align: center !important;
    border-radius: 0px !important;
}

.custom-shiping-content {
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 18px;
    display: flex;
    align-items: center;
    letter-spacing: 0.03em;
    color: #171C26;
    margin: 0.2rem 0rem;
}

.custom-Shipping-text {
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 18px;
    display: flex;
    align-items: center;
    letter-spacing: 0.03em;
    color: #171C26;
}

.custom-shipment-card {
    background: #FFFFFF;
    box-shadow: none !important;
    padding: 0px !important;
}

.custom-shipment-card .MuiCardContent-root {
    padding: 0px !important;
}

.custom-shipping-table-container {
    box-shadow: none !important;
}

.custom-shipment-table-fields {
    overflow-wrap: break-word;
    word-wrap: break-word;
}