
.custom-orderacks-tab .MuiTabs-indicator{
    background-color: #5E5ADB !important;
}
.custom-orderacks-tab .Mui-selected {
    color: #5E5ADB !important ;
    font-weight: 700 !important;
    font-size: 14px !important;
    line-height: 20px !important;
}
.custom-orderacks-tab .MuiTab-textColorPrimary {
    font-weight: 500 !important;
    font-size: 14px !important;
    line-height: 20px !important;
    text-transform: initial !important;
}

/* .custom-orderacks-tabicon{
    position: absolute;
} */
