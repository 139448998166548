.custom-paper-outline{
    margin: 1rem 1rem 1rem 1rem;
}

.custom-box-tab{
    margin:0rem 2rem
}

.custom-tab .css-1h9z7r5-MuiButtonBase-root-MuiTab-root{
    font-size: 12px !important;
    font-weight: 500 !important;
}

.custom-tab .css-1h9z7r5-MuiButtonBase-root-MuiTab-root.Mui-selected{
    font-weight: 700 !important;
}