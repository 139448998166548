.custom_dashboard_table_head {
  font-style: normal;
  font-weight: 600 !important;
  font-size: 14px !important;
  line-height: 24px !important;
}

.custom_dashboard_table_user {
  font-style: normal;
  font-weight: 600 !important;
  font-size: 12px !important;
  line-height: 24px !important;
}

.custom_dashboard_table_count {
  font-style: normal;
  font-weight: 500 !important;
  font-size: 12px !important;
  line-height: 24px !important;
}

.custom_dashboard_table_row {
  margin: 1rem 0rem !important;
  background-color: none !important;
}

.custom_dashboard_table_row:hover {
  background-color: rgb(233 248 251);
}

.custom-dashboard-data-grid .MuiDataGrid-virtualScrollerContent {
  font-size: 12px !important;
}

.custom-dashboard-data-grid .MuiDataGrid-columnHeaderTitle {
  font-size: 16px !important;
  font-weight: 600 !important;
}

.custom-date-picker-dashboard .MuiInputBase-root {
  height: 26px !important;
  border-radius: 6px !important;
  font-size: 13px !important;
}

.custom-date-picker-dashboard .MuiOutlinedInput-input {
  padding: 5.5px 4.5px !important;
  font-size: 12px;
}

.custom-date-picker-dashboard .MuiInputBase-formControl {
  padding-right: 10px !important;
}

.custom-date-picker-dashboard .MuiSvgIcon-fontSizeMedium {
  width: 0.7em !important;
  height: 0.7em !important;
  color:#717a7b;
}
.custom-date-picker-dashboard .MuiSvgIcon-fontSizeMedium:hover {
  color: rgb(87, 85, 255) !important;
}


.custom-dashboard-month-select {
  background: rgb(255 255 255) !important;
  box-shadow: 0px 1px 1px rgb(207, 205, 207) !important;
  border-radius: 12px !important;
  font-size: 12px !important
}

.custom-dashboard-data-grid {
  border-width: 0px !important;
}
.custom-dashboard-piechart-grid{
  padding: 6.2rem 0rem;
}
.custom-dashboard-barchart-container .MuiBarElement-series-pvId {
  width: 30px !important;
  animation: slideInAnimation 1.5s ease-in-out;
  fill: #449ee4 !important;
}
.custom-dashboard-barchart-container .MuiChartsLegend-mark{
  fill: #449ee4 !important;
}
.custom-dashboard-barchart-container rect:hover{
  fill: #449ee4 !important;
}

.custom-width-cell{
  font-size: 14px;
  justify-content: start !important;
  
}
.custom-dashboard-data-grid .MuiDataGrid-columnHeaderTitleContainer{
  justify-content: start !important;
}
.custom-dashboard-data-grid .MuiDataGrid-cell{
  justify-content: start !important;
  padding: 0px 4px !important;
}
.custom-dashboard-data-grid  .MuiDataGrid-selectedRowCount {
  display: none !important;
}
.custom-dashboard-data-grid .MuiDataGrid-footerContainer{
  justify-content: end;
}
.custom-dashboard-data-grid .MuiDataGrid-overlayWrapper{
  height: 50px !important;
}
.custom-dashboard-select-field {
  height: 35px !important;
  border-radius: 6px !important;
}
.custom-select-formcontrol .MuiFormLabel-root{
  line-height: 13px !important;
}
.custom-dashboard-select-field .MuiSelect-select {
  min-height: 0px !important;
  font-size: 12px !important;
}
.custom-dashboard-select-field .MuiSelect-select {
  padding: 5.5px 14px !important;
  line-height: 13px !important;
}
 .MuiDataGrid-cell:focus,.MuiDataGrid-columnHeader:focus {
  outline: none !important;
}
@keyframes slideInAnimation {
  from {
    opacity: 0;
    transform: translateY(100%);
  }

  to {
    opacity: 1;
    transform: translateY(0%);
  }
}
/* media query */
@media screen and (min-device-width: 993px) and (max-device-width: 1023px) {
  .custom-chart-container canvas{
    height: 112px !important;
    width: 88px !important;
   } 
}
@media screen and (min-device-width: 768px) and (max-device-width: 992px) {
  .custom-dashboard-barchart {
    height: 275px !important;
    width: 202px !important;
  }
 .css-1b0lmv8-MuiResponsiveChart-container{
    width: 189px !important;
    height: 252px !important;
  }

  .custom-dashboard-piechart-grid{
    padding: 4.4rem 0rem;
  }
}
@media screen and (min-device-width: 993px) and (max-device-width: 1023px) {
  .custom-chart-container canvas{
    height: 112px !important;
    width: 88px !important;
   } 
   .custom-dashboard-piechart-grid{
    padding: 7.4rem 0rem;
  }
  .custom-dashboard-barchart {
    height: 275px !important;
    width: 345px !important;
  }
  .css-1b0lmv8-MuiResponsiveChart-container{
    width: 189px !important;
    height: 252px !important;
  }
}
@media screen and (min-device-width: 1024px) and (max-device-width: 1279px) {
  .custom-dashboard-barchart {
    height: 275px !important;
    width: 345px !important;
  }
 .css-1b0lmv8-MuiResponsiveChart-container{
    width: 189px !important;
    height: 252px !important;
  }

  .custom-dashboard-piechart-grid{
    padding: 4.4rem 0rem;
  }
}
@media screen and (min-device-width: 1280px) and (max-device-width: 1399px) {
  .custom-dashboard-barchart {
    height: 275px !important;
    width: 345px !important;
  }
 .css-1b0lmv8-MuiResponsiveChart-container{
    width: 259px !important;
    height: 252px !important;
  }

  .custom-dashboard-piechart-grid{
    padding: 4.4rem 0rem;
  }
  .custom-dashboard-month-select .MuiSelect-select{
    padding: 5.5px 14px;
  }
}
@media screen and (min-device-width: 1400px) and (max-device-width: 1900px) {
  .custom-dashboard-barchart {
    height: 275px !important;
    width: 345px !important;
  }
 .css-1b0lmv8-MuiResponsiveChart-container{
    width: 259px !important;
    height: 252px !important;
  }
  .custom-dashboard-piechart-grid {
    padding: 4.4rem 0rem;
}
}