.custom-font-tab {
    font-size: 12px !important;
    width: 130px !important;
    text-transform: initial !important;
}

.custom-badge .MuiBadge-badge {
    margin: 7px -14px 0px 0px !important;
    font-size: 10px;
    background-color: #E9EDF5;
}

.css-19kzrtu {
    padding: 0px !important;
}

.custom-fullfillment-tabs .MuiTabs-indicator {
    background-color: #5E5ADB !important;
}

.custom-fullfillment-tabs .Mui-selected {
    color: #5E5ADB !important;
    font-weight: 700 !important;
    font-size: 14px !important;
    line-height: 20px !important;
}

.custom-font-tab .MuiTab-root {
    font-weight: 500 !important;
    font-size: 14px !important;
    line-height: 20px !important;
}

.custom-fullfillment-tabicon {
    position: absolute;
}

.custom-fullfilmentProceed-btn {
    height: 26px !important;
    padding: 7.4px 5px !important;
    font-size: 12px !important;
    background-color: #5755FF !important;
    box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.1), 0px 0px 0px 1px #5E5ADB !important;
    border-radius: 6px !important;
    text-transform: initial !important;
}