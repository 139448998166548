.custom-dashboard-cardText{
    font-style: normal;
    font-weight: 600 !important;
    font-size: 14px !important;
    line-height: 24px !important;
    align-items: center !important;
    letter-spacing: 0.5px !important;
    color: black !important;
    padding-top: 12px;
}
.custom-dashboard-card-content{
    padding: 16px 16px 16px 16px !important;
}
.custom-dashboard-paper{
    padding: 1rem 0rem 1rem 0rem;
    background: #FFFFFF;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1);
    margin: 1rem 1rem 1rem 1rem;
}
.custom-dashboard-paper-2{
    padding: 1rem 1rem 1rem 1rem;
    background: #FFFFFF !important;
    /* box-shadow: none !important; */
    margin: 1rem 1rem 1rem 1rem;
}
.custom-dashboard-heading{
    font-style: normal;
    font-weight: 700;
    font-size: 25px;
    line-height: 48px;
    color: #0E0D35;
    margin: 0rem 0rem 1rem 0rem;
}
.custom-dashboard-item{
    padding-left: 0px !important;
}
.custom-dashboard-card{
    /* background: #7266BB !important;
    border: 1px solid #7266BB  !important; */
    border-radius: 12px !important;
    text-align: start;
    margin: 0rem 0rem;
    /* padding: 0rem 0rem 0rem 0rem; */
}
.custom-select-formcontrol .MuiFormLabel-root  {
    line-height: 12px !important;
  }
.custom-dashboard-chiplabel .MuiChip-label{
    padding-left: 6px !important;
    padding-right: 6px !important;
}
.custom-dashboard-card-count{
    font-style: normal;
    font-weight: 700 !important;
    font-size: 30px !important;
    line-height: 24px !important;
    align-items: center !important;
    letter-spacing: 0.5px !important;
    color: black !important;
    margin: 1rem 0rem;
    /* padding-left: 17px; */
}
.custom-dashboard-select-field {
    height: 35px !important;
    border-radius: 6px !important;
  }
  
  .custom-dashboard-select-field .MuiSelect-select {
    min-height: 0px !important;
    font-size: 12px !important;
  }
  
  
  /* .custom-dashboard-select-field .MuiSelect-select {
    padding: 8.5px 14px !important;
    line-height: 12px !important;
  } */
  .custom-dashboard-date-picker{
    width: 100% !important;
  }
  
  .custom-dashboard-date-picker .MuiOutlinedInput-input {
    padding: 8.5px 4.5px !important;
    font-size: 12px;
  }
  
  .custom-dashboard-date-picker .MuiInputBase-formControl {
    padding-right: 10px !important;
  }
  
  .custom-dashboard-date-picker .MuiSvgIcon-fontSizeMedium {
    width: 0.7em !important;
    height: 0.7em !important;
    color:#717a7b;
  }
  .custom-dashboard-date-picker .MuiSvgIcon-fontSizeMedium:hover {
    color: rgb(87, 85, 255) !important;
  }
.custom-dashboard-icon-button{
    min-width: 30px !important;
    border-radius: 8px !important;
    padding:8px 8px !important;
}
.dashboard-filter-datepicker{
    width: 100% !important;
}
.dashboard-filter-datepicker .MuiInputBase-input{
    height: 8px !important;
}
.dashboard-filter-datepicker .MuiFormLabel-root{
    line-height: 15px !important;
}
/* @media screen and (min-device-width: 1440px) and (max-device-width: 1599px) {
    .custom-dashboard-card-content{
        padding: 16px 16px 16px 16px !important;
    }
    .custom-dashboard-inner-container{
        justify-content: space-around !important;
    }
    .custom-dashboard-container{
        justify-content: center !important;
    }
    .custom-dashboard-card-count{
        font-size: 27px !important;
    }
}
@media screen and (min-device-width: 1280px) and (max-device-width: 1439px) {
    .custom-dashboard-card-content{
        padding: 16px 16px 16px 16px !important;
    }
    .custom-dashboard-inner-container{
        justify-content: initial !important;
    }
    .custom-dashboard-container{
        justify-content: center !important;
    }
    .custom-dashboard-card-count{
        font-size: 27px !important;
    }
}
@media screen and (min-device-width: 1200px) and (max-device-width: 1279px) {
    .custom-dashboard-card-content{
        padding: 16px 30px 16px 16px !important
    }
    .custom-dashboard-container{
        column-gap: 1rem !important;
    }
    .custom-dashboard-cardText {
        font-size: 12px !important;
    }
    .custom-dashboard-card-count{
        font-size: 27px !important;
    }
} */
@media screen and (min-device-width: 1024px) and (max-device-width: 1119px) {
    .custom-dashboard-card-content{
        padding: 16px 16px 16px 16px !important;
    }
    .custom-dashboard-container{
        column-gap: 0rem !important;
    }
    .custom-dashboard-cardText {
        font-size: 12px !important;
    }
    .custom-dashboard-card-count{
        font-size: 27px !important;
    }
    .custom-dashboard-icon-button {
        padding: 3px 3px !important;
    }
}
@media screen and (min-device-width: 900px) and (max-device-width: 1023px) {
    .custom-dashboard-card-content{
        padding: 16px 16px 16px 16px !important;
    }
    .custom-dashboard-container{
        column-gap: 0rem !important;
    }
    .custom-dashboard-cardText {
        font-size: 12px !important;
    }
    .custom-dashboard-card-count{
        font-size: 27px !important;
    }
    .custom-dashboard-icon-button {
        padding: 3px 3px !important;
    }
}
@media screen and (min-device-width: 600px) and (max-device-width: 899px) {
    .custom-dashboard-card-content{
        padding: 16px 21px 16px 16px !important;
    }
    .custom-dashboard-cardText {
        font-size: 12px !important;
    }
    .custom-dashboard-card-count{
        font-size: 27px !important;
    }
    .custom-dashboard-container{
        column-gap: 2rem !important;
    }
} 