.custom-OrderDetails-orderId{
    font-family: 'Inter';
    font-style: normal;
    font-weight: 700;
    font-size: 12px;
    line-height: 18px;
    display: flex;
    align-items: center;
    text-transform: initial;
    color: #171C26;
    margin:0rem 0rem;
}
.custom-OrderDetails-orderDetails{
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 18px;
    text-transform: initial;
    letter-spacing: 0.03em;
    color: #171C26;
    margin:0rem 0rem;
}
.custom-OrderDetails-otherDetails{
    font-weight: 400;
    font-style: normal;
    font-size: 12px;
    margin: 0rem;
    letter-spacing: 0.02em;
    color: #171C26;
    padding-right: 15px;
}
.custom-OrderDetails-new{
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    margin: 0rem;
    letter-spacing: 0.02em;
    color: #00B929;
}
.custom-OrderDetails-Purchase{
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    margin: 0rem;
    letter-spacing: 0.02em;
    color: #171C26;
    padding-right: 15px;
}
.custom-OrderDetails-container{
    padding: 1rem 2rem 1rem 2rem;
}
