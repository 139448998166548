.custom-Information-Header{
    width: 100%;
    background-color: #E4ECF7 !important;
    color: #171C26 !important;
    font-style: normal;
    font-weight: 700 !important;
    font-size: 12px;
    align-items: center;
    backdrop-filter: blur(4px);
    text-transform: initial !important;
    border-radius: 0px !important;
}
.custom-Information-container{
    padding: 0rem 0rem 1rem 0rem;
    justify-content: center;
}
.custom-Information-TextField-container{
    padding: 0rem 1rem 0rem 1rem;
    justify-content: center;
}
.custom-order-Information{
    padding: 14px;
}
.custom-Information-textField .MuiInputLabel-root,.custom-Information-textField .MuiInputBase-inputSizeSmall {
    font-size: 12px !important;
}