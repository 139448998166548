.custom-basic-details{
    margin:2rem 2rem;
}

.custom-basic-text-field .MuiInputBase-root {
    height: 40px !important;
    background: #FFFFFF;
    border-radius: 6px 6px 6px 6px;
    font-size: 12px !important;
    width: 50ch !important;
}

.custom-basic-text-field .MuiInputBase-input {
    padding: 5px 14px !important;
}

.custom-basic-text-field .css-14s5rfu-MuiFormLabel-root-MuiInputLabel-root{
    font-size: 12px !important;
    line-height: 1em !important;
    letter-spacing: 0.02em !important;
    font-weight: 500 !important;
}

.custom-basic-text-field .css-1jy569b-MuiFormLabel-root-MuiInputLabel-root {
    font-size: 13px !important;
}

.custom-customer-select-field .css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input.MuiSelect-select{
    min-height: 0px !important;
    font-size: 12px !important;
}

.css-kk1bwy-MuiButtonBase-root-MuiMenuItem-root {
    font-size: 12px !important;
}

.custom-customer-select-field .css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input {
    padding: 5.5px 14px !important;
    line-height: 12px !important;
}

.custom-customer-select-field .css-jedpe8-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input {
    padding: 5.5px 14px !important;
    font-size: 12px !important;
}

.custom-customer-select-field {
    height: 40px !important;
    border-radius: 6px !important;
    width: 37.5ch !important;
}

.custom-customer-select-label{
    font-size: 12px !important;
    line-height: 1em !important;
    letter-spacing: 0.02em !important;
    font-weight: 500 !important;
}

.custom-check-internal{
    font-size: 14px !important;
}

.custom-send-btn{
    height: 30px !important;
    text-transform: capitalize !important;
    padding: 0.4px 8px !important;
    font-size: 12px !important;
}

.custom-btn-grid{
    float: right;
}