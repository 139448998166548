.custom-invoiceHeaderEditAddress-head {
    font-weight: 700;
    font-size: 14px;
    line-height: 20px;
    display: flex;
    align-items: center;
    letter-spacing: 0.02em;
    color: #464F60;
}

.custom-invoiceHeaderEditAddress-text {
    font-style: normal;
    font-weight: 500;
    margin: 0;
    font-size: 12px;
    line-height: 20px;
    display: flex;
    align-items: center;
    letter-spacing: 0.02em;
    color: #464F60;
}

.custom-invoiceHeaderEditAddress-editModal-footer {
    border-top: 1px solid #E5E7EA;
}

.custom-invoice-editAddress-container {
    border-bottom: 1px solid #E5E7EA;
}

.custom-invoiceHeaderEditAddress-fields .MuiInputLabel-formControl {
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    align-items: center;
    letter-spacing: 0.02em;
    color: #464F60;
}

.custom-invoiceHeaderEditAddress-fields .MuiInputBase-input {
    font-size: 12px;
}

.custom-invoiceHeaderEditAddress-modal {
    border-radius: 12px;
    padding: 0px 30px 18px 30px;
}

.custom-invoiceHeaderEditAddress-head {
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 48px;
    letter-spacing: -0.022em;
    color: #0E0D35;
    margin: 0rem;
}

.custom-invoiceHeaderEditAddress-head-text {
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 20px;
    display: flex;
    align-items: center;
    letter-spacing: 0.02em;
    color: #464F60;
}

.custom-invoiceHeaderEditAddress-save-btn {
    height: 30px !important;
    padding: 0.4px 8px !important;
    font-size: 12px !important;
    background-color: #5755FF !important;
    color: #FFFFFF !important;
    box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.1), 0px 0px 0px 1px #5E5ADB;
    border-radius: 4px;
    text-transform: initial !important;
}

.custom-invoiceHeaderEditAddress-cancel-btn {
    height: 30px !important;
    padding: 0.4px 8px !important;
    font-size: 12px !important;
    background: #FFFFFF !important;
    color: #464F60 !important;
    text-transform: initial !important;
    box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.1), 0px 0px 0px 1px rgba(70, 79, 96, 0.16);
    border-radius: 4px;
    font-weight: 500;
    line-height: 20px;
    letter-spacing: 0.02em;
}