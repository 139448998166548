.custom-ResubmissionShipping-header {
    width: 100%;
    background-color: #E4ECF7 !important;
    font-style: normal;
    font-weight: 700 !important;
    text-transform: initial !important;
    font-size: 12px;
    text-align: center;
    line-height: 35px;
    color: #171C26 !important;
    backdrop-filter: blur(4px);
}

.custom-ResubmissionShipping-paper {
    padding: 1rem 1rem 0rem 1rem;
    box-shadow: none !important;
}

.custom-ResubmissionShipping-container {
    padding: 0rem 0rem 0rem 0rem;
}

.custom-ResubmissionShipping-bill {
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 20px;
    align-items: center;
    letter-spacing: 0.02em;
    margin: 0;
    color: #464F60;
}

.custom-ResubmissionShipping-bill-head {
    font-style: normal;
    font-weight: 600;
    margin: 0;
    font-size: 12px;
    line-height: 20px;
    align-items: center;
    letter-spacing: 0.02em;
    color: #464F60;
}

.custom-ResubmissionShipping-viewAddress {
    margin: 0;
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 20px;
    letter-spacing: 0.25px;
    color: #5755FF;
    cursor: pointer;
}

.custom-ResubmissionShipping-checkbox .MuiFormControlLabel-label {
    font-size: 12px;
}