.custom-PaymentTerm-bill {
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 20px;
    display: flex;
    align-items: center;
    letter-spacing: 0.02em;
    margin: 0;
    color: #464F60;
}

.custom-PaymentTerm-header {
    width: 100%;
    background-color: #E4ECF7 !important;
    text-transform: initial !important;
    font-style: normal;
    font-weight: 700 !important;
    font-size: 14px;
    text-align: center;
    line-height: 35px;
    color: #171C26 !important;
    backdrop-filter: blur(4px);
}

.custom-PaymentTerm-container {
    justify-content: space-around !important;
}

.custom-PaymentTerm-paper {
    padding: 1rem 1rem 0rem 1rem;
    box-shadow: none !important;
}

.custom-PaymentTerm-save-btn {
    height: 24px !important;
    padding: 0.4px 8px !important;
    font-size: 12px !important;
    background-color: #5755FF !important;
    color: #FFFFFF !important;
    box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.1), 0px 0px 0px 1px #5E5ADB;
    border-radius: 4px;
    text-transform: initial !important;
}

.custom-PaymentTerm-cancel-btn {
    height: 24px !important;
    padding: 0.4px 8px !important;
    font-size: 12px !important;
    background: #FFFFFF !important;
    color: #464F60 !important;
    text-transform: initial !important;
    box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.1), 0px 0px 0px 1px rgba(70, 79, 96, 0.16);
    border-radius: 4px;
    font-weight: 500;
    line-height: 20px;
    letter-spacing: 0.02em;
}

.custom-PaymentTerm-textfield .MuiInputBase-input {
    height: 0.7375em !important;
    font-size: 12px;

}