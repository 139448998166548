
.custom-totalAmount{
    justify-content: end;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 18px;
    display: flex;
    align-items: center;
    text-align: start;
}
.custom-totalamout-container{
    padding: 0rem 2rem 0rem 0rem;
}
.custom-LineDetails-Header{
    width: 100%;
    background-color: #E4ECF7 !important;
    color: #171C26 !important;
    font-style: normal;
    font-weight: 700 !important;
    font-size: 14px;
    align-items: center;
    backdrop-filter: blur(4px);
    line-height: 34px;
    border: none;
    border-radius: 0px !important;
}
.custom-LineDetails-container{
    padding: 0rem 0rem 0rem 0rem;
}
.custom-Create-Order-Shipment{
    width: auto;
    background-color: #5755FF !important;
    box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.1), 0px 0px 0px 1px #5E5ADB;
    border-radius: 6px !important;
    border: 0px;
    font-style: normal;
    font-weight: 500 !important;
    font-size: 12px !important;
    line-height: 25px;
    cursor: pointer;
    color: #ffff !important;
    backdrop-filter: blur(4px);
    padding: 0px 8px !important;
}
.custom-LineItems-table-header{
    font-style: normal;
    font-weight: 500 !important;
    font-size: 12px !important;
    line-height: 6px !important;
    align-items: center;
    letter-spacing: 0.03em;
    text-transform: initial !important;
    color: #687182 !important;
}
.custom-LineItems-table-fields{
    font-style: normal;
    font-weight: 400 !important;
    font-size: 12px !important;
    line-height: 20px;
    align-items: center;
    letter-spacing: 0.02em;
    color: #171C26 !important;
}
.custom-OrderAcksLineDetailsTable-item-table{
    padding: 0rem 1rem 0rem 1rem;
}
.custom-shipping-table-container .MuiTableRow-head{
    background-color: #F4F7FC;
}