.custom-extrinsicInformation-header {
    width: 100%;
    background-color: #E4ECF7 !important;
    font-style: normal;
    font-weight: 700 !important;
    text-transform: initial !important;
    font-size: 14px;
    text-align: center;
    line-height: 35px;
    color: #171C26 !important;
    backdrop-filter: blur(4px);
}

.custom-extrinsicInformation-textField {
    background: #FFFFFF;
    border-radius: 6px;
    font-style: normal;
    font-weight: 700;
    font-size: 12px !important;
    color: #464F60 !important;
}

.custom-extrinsicInformation-textField .MuiInputLabel-formControl,
.custom-extrinsicInformation-textField .MuiInputBase-input {
    font-size: 12px !important;
    color: #464F60 !important;
}

.custom-extrinsicInformation-paper {
    padding: 0rem 1rem 0rem 1rem;
    box-shadow: none !important;
}

.custom-extrinsicInformation-container {
    padding: 0rem 0rem 0rem 0rem;
}