.custom-addItem-details {
    margin: 1rem 1rem;
}

.custom-addItem-paper {
    padding: 1rem 1rem 2rem 1rem;
}

.custom-addItem-text-field .MuiInputBase-root {
    height: 40px !important;
    background: #FFFFFF;
    border-radius: 6px 6px 6px 6px;
    font-size: 12px !important;
}

.custom-addItem-text-field .MuiInputBase-input {
    padding: 5px 14px !important;
}

.custom-addItem-text-field .MuiInputLabel-formControl {
    font-size: 12px !important;
    line-height: 1em !important;
    letter-spacing: 0.02em !important;
    font-weight: 500 !important;
}

.custom-customer-addItem-select-field .MuiSelect-select {
    min-height: 0px !important;
    font-size: 12px !important;
    padding: 5.5px 14px !important;
    line-height: 12px !important;
}

.custom-customer-addItem-select-field {
    height: 40px !important;
    border-radius: 6px !important;
}

.custom-customer-addItem-select-field-1 {
    height: 40px !important;
    border-radius: 6px !important;
    text-align: start !important;
    padding: 5.5px 0px !important;
    font-size: 12px !important;
    line-height: 12px !important;
}

.custom-customer-addItem-select-label {
    font-size: 12px !important;
    line-height: 1em !important;
    letter-spacing: 0.02em !important;
    font-weight: 500 !important;
}

.custom-check-internal {
    font-size: 14px !important;
}

.custom-addItem-send-btn {
    height: 24px !important;
    text-transform: capitalize !important;
    padding: 0.4px 8px !important;
    font-size: 12px !important;
    background-color: #5755FF !important;
    color: #FFFFFF !important;
    box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.1), 0px 0px 0px 1px #5E5ADB;
    border-radius: 4px;
    min-width: 61px !important;
}

.custom-addItem-delete-btn {
    height: 24px !important;
    text-transform: capitalize !important;
    padding: 0.4px 8px !important;
    font-size: 12px !important;
    background: #FFFFFF !important;
    color: #464F60 !important;
    box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.1), 0px 0px 0px 1px rgba(70, 79, 96, 0.16);
    border-radius: 4px;
    min-width: 61px !important;
}

.custom-btn-grid {
    float: right;
}

.custom-addItem-heading {
    font-style: normal;
    font-weight: 700;
    font-size: 23px;
    letter-spacing: -0.022em;
    color: #0E0D35;
    line-height: 0px;
}

.custom-addItem-container {
    column-gap: 2rem;
}

.custom-item-spinner {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}