.custom-fulfillment-header {
    padding: 0rem 1rem !important;
    font-size: 23px;
}

.custom-icon {
    margin: 1px 5px 1px 5px;
    color: #A1ACB3;
    cursor: pointer;
}
.custom-download-icon-button:hover {
    background-color: rgba(0, 0, 0, 0.00) !important;
}

.custom-icon:hover {
    /* background-color: rgba(0, 0, 0, 0.04); */
    border-radius: 50%;
}

.icon-padding {
    padding: 0px 1px;
}

.custom-table-icon {
    font-size: 30px;
    margin: auto;
}

.custom-text-field .MuiInputBase-root {
    height: 26px !important;
    background: #FFFFFF;
    border-radius: 6px 6px 6px 6px;
    font-size: 12px !important;
    /* width: 225px !important; */
}

.custom-text-field .MuiInputBase-input {
    padding: 5px 0px !important;
}

.custom-text-field .MuiInputBase-formControl {
    padding-left: 7px !important;
}

.custom-select-field {
    height: 26px !important;
    border-radius: 6px !important;
}

.custom-select-field .MuiSelect-select {
    min-height: 0px !important;
    font-size: 12px !important;
}

.css-kk1bwy-MuiButtonBase-root-MuiMenuItem-root {
    font-size: 12px !important;
}

.custom-select-field .MuiSelect-select {
    padding: 5.5px 14px !important;
    line-height: 12px !important;
}

.custom-date-picker .MuiInputBase-root {
    height: 26px !important;
    border-radius: 6px !important;
    font-size: 13px !important;
}

.custom-date-picker .MuiOutlinedInput-input {
    padding: 5.5px 4.5px !important;
    font-size: 12px;
}

.custom-date-picker .MuiInputBase-formControl {
    padding-right: 10px !important;
}

.custom-date-picker .MuiSvgIcon-fontSizeMedium {
    width: 0.7em !important;
    height: 0.7em !important;
    color:#717a7b;
}
.custom-date-picker .MuiSvgIcon-fontSizeMedium:hover {
    color: rgb(87, 85, 255) !important;
}

.custom-order-btn {
    height: 26px !important;
    padding: 7.4px 5px !important;
    font-size: 12px !important;
    background-color: #5755FF !important;
    box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.1), 0px 0px 0px 1px #5E5ADB !important;
    border-radius: 6px !important;
    text-transform: initial !important;
}

.custom-fulfillment-AutoProcess {
    margin: 0;
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 20px;
    align-items: center;
    letter-spacing: 0.02em;
    color: #464F60;
    padding: 3px 4px 0px 0px;
}

.custom-fulfillment-icon {
    font-size: 18px;
    box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.1), 0px 0px 0px 1px rgba(70, 79, 96, 0.16);
    border-radius: 4px;
    margin: 1px 0px 0px 7px;
    height: 24px;
    width: 24px;
    text-align: center;
    padding-top: 3px;
}

.custom-fullfillment-filter-row {
    justify-content: space-between;
}

.custom-fulfillment-table-icon {
    cursor: pointer;
}
.custom-fulfillment-table-icon:hover {
   color: rgb(87, 85, 255) !important;
}

@media only screen and (max-device-width: 320px) {
    .custom-fulfillment-table-icon {
        height: 15px !important;
        width: 15px !important;
    }

    .custom-fulfillment-icon {
        font-size: 11px;
        margin: 9px0px 0px 0px;
        height: 26px;
        width: 26px;
        padding-top: 6px;
        border-radius: 4px;
    }

    .custom-text-field .MuiInputBase-input {
        padding: 5px 2px !important;
    }

    .custom-date-picker .MuiOutlinedInput-input {
        padding: 8.5px 8px !important;
    }
}

@media screen and (min-device-width: 320px) and (max-device-width: 539px) {
    .custom-fulfillment-table-icon {
        height: 15px !important;
        width: 15px !important;
    }

    .custom-fulfillment-icon {
        font-size: 11px;
        margin: 9px0px 0px 0px;
        height: 26px;
        width: 26px;
        padding-top: 6px;
        border-radius: 4px;
    }

    .custom-fullfillment-filter-grid {
        justify-content: space-evenly;
    }

    .custom-text-field .MuiInputBase-input {
        padding: 5px 2px !important;
    }

    .custom-date-picker .MuiOutlinedInput-input {
        padding: 8.5px 8px !important;
    }
}

@media screen and (min-device-width: 540px) and (max-device-width: 767px) {
    .custom-fulfillment-table-icon {
        height: 15px !important;
        width: 15px !important;
    }

    .custom-fulfillment-icon {
        font-size: 11px;
        margin: 9px0px 0px 0px;
        height: 26px;
        width: 26px;
        padding-top: 6px;
        border-radius: 4px;
    }

    .custom-fullfillment-filter-grid {
        justify-content: space-evenly;
    }

    .custom-date-picker .MuiOutlinedInput-input {
        padding: 5.5px 5px !important;
    }
}

@media screen and (min-device-width: 768px) and (max-device-width: 992px) {
    .custom-fulfillment-table-icon {
        height: 15px !important;
        width: 15px !important;
    }

    .custom-fulfillment-icon {
        font-size: 11px;
        margin: 9px0px 0px 0px;
        height: 26px;
        width: 26px;
        padding-top: 6px;
        border-radius: 4px;
    }

    .custom-fullfillment-filter-grid {
        justify-content: space-evenly;
    }

    .custom-date-picker .MuiOutlinedInput-input {
        padding: 5.5px 5px !important;
    }
}

@media screen and (min-device-width: 900px) and (max-device-width: 1199px) {
    .custom-fullfillment-filter-grid {
        justify-content: space-evenly;
    }
}