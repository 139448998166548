.custom-customer-header{
    padding: 0rem 1rem !important;
    font-size: 23px;
}

.custom-icon{
    margin: 1px 5px 1px 5px;
    color: #A1ACB3;
}

.icon-padding {
    padding: 0px 1px;
}

.custom-table-icon{
    font-size: 30px;
    margin: auto;
}

/* .custom-text-field .MuiInputBase-root {
    height: 30px !important;
    background: #FFFFFF;
    border-radius: 6px 6px 6px 6px;
    font-size: 12px !important;
}

.custom-text-field .MuiInputBase-input {
    padding: 5px 14px !important;
} */

.custom-customer-btn{
    height: 30px !important;
    text-transform: capitalize !important;
    padding: 0.4px 8px !important;
    font-size: 12px !important;
}

.custom-add-icon{
    font-size: 15px !important;
}