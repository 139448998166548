.custom-login-heading {
    font-style: normal;
    font-weight: 700 !important;
    font-size: 28px !important;
    line-height: 48px !important;
    color: #171C26 !important;
    text-align: start;
}

.custom-login-button {
    background: #FFA400 !important;
    border-radius: 100px !important;
    font-style: normal !important;
    font-weight: 600 !important;
    font-size: 14px !important;
    line-height: 20px !important;
    color: #FFFFFF !important;
    text-transform: initial !important;
}

.custom-login-textfield .MuiInputBase-input {
    font-size: 12px !important;
}

.custom-login-textfield .MuiSvgIcon-root {
    height: 18px !important;
    width: 18px !important;
}

@media screen and (min-device-width: 2560px) and (max-device-width: 2921px) {
    .custom-login-heading {
        padding: 0rem 0rem 0rem 7rem;
    }
}