.custom-status-paper {
    padding: 2px 5px 2px 5px;
    box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0) !important;
}

.custom-link-order {
    color: #5E5ADB;
    text-decoration: none !important;
}

.custom-item-table .tss-iwylj0-MUIDataTableBodyCell-responsiveStackedSmallParent {
    padding: 8px !important;
    font-size: 12px;
}

.custom-item-table .tss-1akey0g-MUIDataTableHeadCell-data {
    font-size: 12px;
    color: #687182;
    font-weight: 500;
}

.custom-item-table .tss-1vd39vz-MUIDataTableBodyCell-stackedCommon {
    font-size: 12px;
    color: #171C26;
    font-weight: 400;
}

.custom-item-table .tss-gm6zfk-MUIDataTableHeadCell-fixedHeader {
    padding: 7px !important;
    font-size: 14px !important;
}

.custom-item-table .MuiTableCell-head {
    background-color: #E4ECF7;
}

.custom-item-table .tss-1fbujeu-MUIDataTableHeadCell-toolButton {
    min-width: 16px !important;
    padding: 2px 7px !important;
    font-size: 12px !important;
    font-weight: 500 !important;
}

.custom-item-table .tss-1dci9uv-MUIDataTableSelectCell-checkboxRoot {
    padding: 4px !important;
}

.custom-item-table .MuiSvgIcon-root {
    font-size: 16px !important;
}

.custom-item-table .tss-hj53wm-MUIDataTableToolbar-titleText {
    font-size: 16px !important;
}

.custom-item-table .tss-1x5mjc5-MUIDataTable-root {
    border-radius: 0px !important;
    box-shadow: none !important;
}

.custom-item-table .tss-tlx3x1-MUIDataTableToolbar-root {
    display: none !important;
}

.custom-status-icon {
    font-size: 8px;
    margin: 0px 4px 0px 0px;
}

.custom-item-table .tss-1ork7hi-MUIDataTablePagination-tableCellContainer {
    padding: 0px !important;
}

.custom-item-table .tss-1qtl85h-MUIDataTableBodyCell-root {
    font-size: 12px !important;
    padding: 4px !important;
}

.custom-item-table {
    margin: 1rem
}


.custom-item-table .tss-mwfche-MUIDataTableBodyRow-root.mui-row-selected {
    background-color: #F4F7FC;
}

.custom-item-table .tss-1qjwhn0-MUIDataTableBody-emptyTitle {
    font-size: 12px;
}

.custom-item-table .tss-1dqj55d-MUIDataTableToolbarSelect-root {
    display: none !important;
}

.custom-item-table .tss-mwfche-MUIDataTableBodyRow-root.MuiTableRow-hover:hover {
    background-color: #ffffff;
}

.custom-item-table .tss-1cibt4j-MUIDataTableBodyRow-root-MUIDataTableBody-lastStackedCell.mui-row-selected {
    background-color: #ffffff !important;
}