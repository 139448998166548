.custom-order-acks-timeline{
    padding: 2rem 14rem 1rem 14rem !important;
}

.custom-active-stepper{
    box-shadow: 0 0 5px #000000;
    border-color:  #ff0000 ;
}

.custom-active-text .MuiStepLabel-label{
    color: #00B929 !important;
}
