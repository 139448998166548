.custom-tax-summary{
    width: 100%;
    background-color: #E4ECF7 !important;
    color: #171C26 !important;
    font-style: normal;
    line-height: 12px !important;
    font-weight: 700 !important;
    font-size: 12px;
    align-items: center;
    color: #171C26 !important;
    backdrop-filter: blur(4px);
    text-align: center !important;
    border-radius: 0px !important;
}

.custom-tax-totalAmount{
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 18px;
    align-items: center;
}
.custom-amountSummary{
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 12px;
    letter-spacing: 0.03em;
    color: #171C26;
    margin-bottom: 0px;
}
.custom-Create-Shipment{
    width: auto;
    background-color: #5755FF !important;
    box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.1), 0px 0px 0px 1px #5E5ADB !important;
    border-radius: 6px !important;
    border: 0px;
    font-style: normal;
    font-weight: 500 !important;
    text-transform: initial !important;
    font-size: 12px !important;
    line-height: 25px;
    color: #ffff !important;
    backdrop-filter: blur(4px);
    padding: 0px 8px !important;
}
.custom-Create-sentAriba{
    width: auto;
    background-color: #5755FF !important;
    box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.1), 0px 0px 0px 1px #5E5ADB !important;
    border-radius: 6px !important;
    border: 0px;
    font-style: normal;
    font-weight: 500 !important;
    text-transform: initial !important;
    font-size: 12px !important;
    line-height: 25px;
    color: #ffff !important;
    backdrop-filter: blur(4px);
    padding: 0px 8px !important;
    margin-right: 0rem !important;
}
.custom-tax-container{
    padding: 0rem 0rem 1rem 0rem;
}
.custom-order-tax{
    padding: 14px;
}
.swal2-container{
    z-index: 10000 !important;
}
.custom-Create-sentAriba{
    width: auto;
    background-color: #5755FF !important;
    box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.1), 0px 0px 0px 1px #5E5ADB !important;
    border-radius: 6px !important;
    border: 0px;
    font-style: normal;
    font-weight: 500 !important;
    text-transform: initial !important;
    font-size: 12px !important;
    line-height: 25px;
    color: #ffff !important;
    backdrop-filter: blur(4px);
    padding: 2px 14px !important;
}