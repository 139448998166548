.custom-status-paper {
    padding: 2px 5px 2px 5px;
    box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0) !important;
}

.custom-link-order {
    color: #5E5ADB;
    text-decoration: none !important;
}

.custom-data-table .tss-iwylj0-MUIDataTableBodyCell-responsiveStackedSmallParent {
    padding: 5px !important;
    font-size: 12px;
}

.custom-data-table .tss-gm6zfk-MUIDataTableHeadCell-fixedHeader {
    padding: 7px !important;
    font-size: 14px !important;
    background-color: #F4F7FC;
    border: 1px solid #E5E7EA;
    backdrop-filter: blur(4px);
}

.custom-data-table .tss-ylo811-MUIDataTableSelectCell-headerCell {
    background-color: #F4F7FC;
}

.custom-data-table .tss-1fbujeu-MUIDataTableHeadCell-toolButton {
    min-width: 16px !important;
    padding: 2px 7px !important;
    font-size: 12px !important;
    font-weight: 500 !important;
    color: #687182 !important;
    text-transform: initial !important;
}

.custom-data-table .tss-1dci9uv-MUIDataTableSelectCell-checkboxRoot {
    padding: 4px !important;
}

.custom-data-table .MuiSvgIcon-root {
    font-size: 16px !important;
}

.custom-data-table .tss-hj53wm-MUIDataTableToolbar-titleText {
    font-size: 16px !important;
}

.custom-data-table .tss-1x5mjc5-MUIDataTable-root {
    border-radius: 0px !important;
    box-shadow: none !important;
}

.custom-data-table .tss-tlx3x1-MUIDataTableToolbar-root {
    display: none !important;
}

.custom-data-table .tss-1qtl85h-MUIDataTableBodyCell-root {
    font-size: 12px !important;
    color: #171C26 !important;
}

.custom-status-icon {
    font-size: 8px;
    margin: 0px 4px 0px 0px;
}

.custom-data-table .tss-1ork7hi-MUIDataTablePagination-tableCellContainer {
    padding: 0px !important;
}

.custom-expansion .MuiTimelineItem-missingOppositeContent:before {
    content: none !important;
}

.custom-expansion .MuiTimelineDot-root {
    background-color: #D0CFFC !important;
}

.custom-expansion .MuiTimelineContent-root {
    padding-top: 6px !important;
    padding-left: 11px !important;
}
.custom-expansion .MuiTimelineItem-positionRight {
    min-height: 38px !important;
}

.custom-last-comment {
    border-left: 1px solid #D5DBE5;
    padding-left: 3px;
    font-size: 12px !important;
    font-weight: 400 !important;
    line-height: 20px !important;
    color: #464F60 !important;
    font-style: italic !important;
}

.custom-order-acknowledgement {
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 20px;
    display: flex;
    align-items: center;
    letter-spacing: 0.02em;
    color: #171C26
}

.custom-order-date {
    font-style: normal;
    font-weight: 500;
    font-size: 10px;
    line-height: 20px;
    display: flex;
    align-items: center;
    letter-spacing: 0.02em;
    color: #687182
}

.custom-order-name {
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 20px;
    display: flex;
    align-items: center;
    letter-spacing: 0.02em;
    color: #5A6376;
    border-radius: 4px;
    border-color: #D5DBE5;
}

.custom-order-name-button {
    font-style: normal;
    font-weight: 500;
    font-size: 12px !important;
    line-height: 20px;
    height: 24px;
    display: flex;
    width: auto !important;
    padding: 2px 2px 2px 2px !important;
    align-items: center;
    letter-spacing: 0.02em;
    color: #5A6376 !important;
    text-transform: initial !important;
    background: #E9EDF5 !important;
    border-radius: 4px;
    border-color: #D5DBE5 !important;
}

.custom-order-name-button:hover {
    font-style: normal;
    font-weight: 500;
    font-size: 12px !important;
    line-height: 20px;
    height: 24px;
    display: flex;
    align-items: center;
    letter-spacing: 0.02em;
    color: #5A6376 !important;
    background: #E9EDF5 !important;
    border-radius: 4px;
    border-color: #D5DBE5 !important;
}

.custom-data-table .tss-1qtl85h-MUIDataTableBodyCell-root {
    padding: 3px !important;
}

.custom-expansion {
    width: 100% !important;
    margin-top: 0px !important;
    padding-top: 0px !important;
}

.custom-data-table .tss-1dqj55d-MUIDataTableToolbarSelect-root {
    display: none !important;
}

.custom-data-table .tss-mwfche-MUIDataTableBodyRow-root .MuiTableRow-hover:hover {
    background-color: #FFFFFF;
}

.custom-data-table .tss-mwfche-MUIDataTableBodyRow-root .MuiTableRow-hover:hover {
    background-color: #D0CFFC !important;
}

.custom-expansion .MuiTimelineConnector-root {
    background-color: #bdbdbd !important;
}

.custom-data-table .MuiSvgIcon-root {
    /* color: rgba(0, 0, 0, 0.1) !important; */
    border-radius: 4px !important;
}

.custom-data-table .tss-mwfche-MUIDataTableBodyRow-root.mui-row-selected {
    background-color: #F4F7FC;
}

.custom-data-table .tss-1qjwhn0-MUIDataTableBody-emptyTitle {
    font-size: 12px;
}

.custom-timeline-text {
    font-style: normal;
    font-weight: 400;
    font-size: 10px;
    line-height: 23px;
    letter-spacing: 0.03em;
    text-transform: initial;
    color: #171C26;
}

.custom-fulfillment-comments {
    padding-left: 3rem;
    font-size: 12px;
    line-height: 14px;
    letter-spacing: 0.03em;
    text-transform: initial;
    color: #171C26;
}