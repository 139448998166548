.custom-orderInformation-header {
    width: 100%;
    background-color: #E4ECF7 !important;
    text-transform: initial !important;
    font-style: normal;
    font-weight: 700 !important;
    font-size: 14px;
    text-align: center;
    line-height: 35px;
    color: #171C26 !important;
    backdrop-filter: blur(4px);
}

.custom-orderInformation-textField .MuiInputLabel-formControl {
    background: #FFFFFF;
    border-radius: 6px;
    font-weight: 500;
    font-size: 12px !important;
    line-height: 26px;
    align-items: center;
    color: #464F60 !important;
}


.custom-orderInformation-dateField .MuiInputBase-input {
    font-size: 12px !important;
    color: #464F60 !important;
}

.custom-orderInformation-dateField .MuiInputLabel-outlined {
    line-height: 12px !important;
    font-size: 12px;
    color: #464F60 !important;
}

.custom-orderInformation-dateField {
    background: #FFFFFF;
    border: 1px solid #E5E7EA;
    border-radius: 6px;
    font-style: normal;
    font-weight: 500;
    font-size: 14px !important;
    line-height: 20px;
    align-items: center;
    letter-spacing: 0.02em;
    color: #464F60 !important;
}

.custom-orderInformation-dateField .MuiInputBase-formControl {
    height: 2.4em;
    width: 14rem;
    color: #464F60;
    line-height: 1em !important;
}

.custom-orderInformation-paper {
    padding: 1rem 1rem 0rem 1rem;
    box-shadow: none !important;
}